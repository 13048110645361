import React from "react"

import { Link } from "gatsby"

import "./404.css"

const NotFound = () => {
  return (
    <div className="custombody">
      <div className="mainbox">
        <div className="err">4</div>
        <i className="far fa-question-circle fa-spin"></i>
        <div className="err2">4</div>
        <div className="msg">
          Ooops!! parece que la página que buscas no existe...
          <p>
            Vamos a nuestra pagina de&nbsp;
            <Link className="a" to="/">
               Inicio
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

export default NotFound
